<template>
  <div id="submenu">
    <a href="/company/overview" class="list-group-item" :class="{active: menu_list.overview}">Overview <i class="fa fa-chevron-right"></i></a>
    <a href="/company/recognitions-spotlights" class="list-group-item" :class="{active: menu_list.spotlight}">Recognitions & Spotlights <i class="fa fa-chevron-right"></i></a>
    <a href="/company/events" class="list-group-item" :class="{active: menu_list.event}">Events <i class="fa fa-chevron-right"></i></a>


      <a href="/company/careers" class="list-group-item" :class="{active: menu_list.career}">Careers  
        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right arrow" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
        </svg>
      </a>
      <div>
        <a href="/company/why-join-malabs" class="list-group-item" :class="{active: menu_list.WhyJoinMalabs}" >Why Join Ma Labs</a>
        <a href="/company/work-at-malabs" class="list-group-item" :class="{active: menu_list.WorkAtMalabs}" >Work at Ma Labs</a>
        <a href="/company/job-openings" class="list-group-item" :class="{active: menu_list.JobOpenings}" >Job Openings <i class="fa fa-chevron-right"></i></a>
        <a href="/company/career-events" class="list-group-item" :class="{active: menu_list.CareerEvents}" >Career Events <i class="fa fa-chevron-right"></i></a>
      </div>   

    <a href="/company/our-branches" class="list-group-item" :class="{active: menu_list.branch}">Our Branches <i class="fa fa-chevron-right"></i></a>
    <a href="/company/newsletter" class="list-group-item" :class="{active: menu_list.subscribe}"> Email Newsletters <i class="fa fa-chevron-right"></i></a>
    <a href="/company/privacy-policy" class="list-group-item" :class="{active: menu_list.policy}">Privacy Policy <i class="fa fa-chevron-right"></i></a>
    <a href="/company/cookie-notice" class="list-group-item" :class="{active: menu_list.cookie}">Cookie Notice <i class="fa fa-chevron-right"></i></a>
    <a href="/company/notice" class="list-group-item" :class="{active: menu_list.notice}">Coronavirus <i class="fa fa-chevron-right"></i></a>
    <a href="/company/ukraine-crisis" class="list-group-item" :class="{active: menu_list.UkraineCrisis}">Ukraine Crisis <i class="fa fa-chevron-right"></i></a>
  </div>
 
</template>

<script>
import { ref } from 'vue'

export default {
  props: [ 'menu' ],
  setup(props) {
    const menu_list = ref({
        overview: false,
        spotlight: false,
        event: false,
        branch: false,
        policy: false,
        cookie: false,
        notice: false,
        career: false,
        subscribe: false,
        Whyjoinmalabs:false,
        WorkAtMalabs:false,
        JobOpenings:false,
        CareerEvents:false,
        UkraineCrisis:false
      })
    let careerList = new Set(["WhyJoinMalabs", "WorkAtMalabs", "JobOpenings", "CareerEvents"])
    if (careerList.has(props.menu)){
      menu_list.value["career"] = true
    }   
    menu_list.value[props.menu] = true
     
    return { menu_list }
  }
}
</script>


<style scoped>
.arrow{
  float: right;
}
</style>