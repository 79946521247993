<template>
  <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb" id="breadcrumb_container">
    <ol class="breadcrumb">
      <li v-for="item in breadcrumb_list" class="breadcrumb-item">
        <span v-if="item.link">
          <router-link :to="{ name: item.link }">{{item.text}}</router-link>
        </span>
        <span v-else-if="item.url">
          <router-link :to="item.url">{{item.text}}</router-link>
        </span>
        <span v-else>{{item.text}}</span>
      </li>
    </ol>
  </nav>
</template>

<script>
import { ref, toRefs, watch } from 'vue'

export default {
  props: [ 'list' ],
  setup(props) {
    const breadcrumb_list = ref(null)
    breadcrumb_list.value = props.list
    
    const propsRef = toRefs(props)
    watch(propsRef.list, function(newValue, oldValue){
      breadcrumb_list.value = newValue
    })

    return { breadcrumb_list }
  }
}
</script>

<style scoped>
a{
  color:#555;
}

</style>