<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <company-submenu menu="event"></company-submenu>

    <div class="submenu-content">

        <div v-if="error" class="alert alert-danger">
            {{error}}
        </div>
        <div v-else>
            <div v-if="item.id==70 || item.slug.includes('tech-fair-2022')">
              <iframe src="/media/landing_page/techfair2022/index.html" frameborder="0" width="100%" height="3500">

              </iframe>
            </div>
            <div v-else>
              <p class="lead fs-2">{{item.title}}</p>
              <p v-if="item.dates" >
                  <div class="fw-bold">Date: </div> 
                  {{item.dates}}
              </p>
              <p v-else >
                  <div class="fw-bold">Date: </div> 
                  {{item.date}}
              </p>
              <p v-if="item.location || item.address ">
                  <div class="fw-bold">Location: </div>  
                  {{item.location}}
                  <div v-if="item.address" v-html="item.address" class="address"></div>
              </p>

              <br/>
              <div v-if="item.flyer_link">
                <a :href="item.flyer_link" target="_blank">
                  <img v-if="item.flyer" :src="item.flyer.meta.download_url" >
                </a>
              </div>
              <div v-else>
                  <img v-if="item.flyer" :src="item.flyer.meta.download_url" >
              </div>
            </div>
            
        </div>
    </div>
  </div>
</template>

<script>
import CompanySubmenu from "../../components/nav/CompanySubmenu.vue"
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { getAPI } from '../../utils/axios-api'
import { ref } from "vue";

export default {
  name: "EventDetails",
  components: { CompanySubmenu, BreadcrumbPage  },
  props: ["slug"],
  setup(props) {
    const error = ref(null)
    const item = ref(null)
    const event_title = ref(props.slug)

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Company", link: ""})
    breadcrumb_links.value.push({text: "Events", link: "Events"})
    
    
    getAPI
    .get("content/events/?slug=" + props.slug)
    .then((response) => {
        if (response.data.items.length == 0) {
            error.value = "Page not found"
            breadcrumb_links.value.push({text: props.slug, link: ""})
        }
        else {
            item.value = response.data.items[0]
            event_title.value = item.value.title
            breadcrumb_links.value.push({text: event_title.value, link: ""})
        }
    })
    .catch(err => {
      error.value = err
    })

    return { error, item, event_title, breadcrumb_links }
    
  }
}
</script>

<style scoped>
.address {
  line-height: 10px;
    margin-top:10px;
}

</style>